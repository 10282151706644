import React, { useState, useEffect } from 'react';
import { IoIosArrowUp } from "react-icons/io";
import './ScrollToTopButton.css';

const ScrollToTopButton = ({ isSidebarOpen }) => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const toggleVisibility = () => {
            if (window.pageYOffset > 300 && !isSidebarOpen) {
                setIsVisible(true);
            } else {
                setIsVisible(false);
            }
        };

        window.addEventListener('scroll', toggleVisibility);

        return () => {
            window.removeEventListener('scroll', toggleVisibility);
        };
    }, [isSidebarOpen]);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    return (
        <div 
            className={`scroll-button ${isVisible ? 'visible' : 'hidden'} ${isSidebarOpen ? 'sidebar-open' : ''}`} 
            onClick={scrollToTop}
        >
            <IoIosArrowUp />
        </div>
    );
};

export default ScrollToTopButton;
