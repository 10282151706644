import React, { useState, useContext } from "react";
import { motion, AnimatePresence } from "framer-motion";
import "../Frequently.css";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { LanguageContext } from '../Main/LanguageContext.js';
import translations from "../../Translations";
import Footer from "../../components/Main/Footer.js";
import OffcanvasExample from '../../components/Main/NavBar';


const TermsConditions = ({ toggleDarkMode, toggleDarkTheme }) => {
    const [activeId, setActiveId] = useState(null);
    const { language } = useContext(LanguageContext);
    const t = translations[language];
    const direction = language === 'ar' ? 'rtl' : 'ltr';
    const langClass = language === 'ar' ? 'arabic' : 'english';

    const handleToggle = (id) => {
        if (activeId === id) {
            setActiveId(null); // Close if the same item is clicked
        } else {
            setActiveId(id); // Open the clicked item
        }
    };

    return (
        <>
            <OffcanvasExample toggleDarkMode={toggleDarkMode} toggleDarkTheme={toggleDarkTheme} />
            <div className={`frq-main-container ${langClass}`} id="terms" dir={direction}>
                <div className="container">
                    <header>
                        <h2 className="questions">{t.termsTitle}</h2>
                    </header>

                    <div className="container-frq">
                        <div className="accordionBlock">
                            {t.conditions.map((condition) => (
                                <div key={condition.id} className="condition-item">
                                    <div className="question" onClick={() => handleToggle(condition.id)}>
                                        <h2>{condition.que}</h2>
                                        <p>
                                            {activeId === condition.id ? <IoIosArrowUp /> : <IoIosArrowDown />}
                                        </p>
                                    </div>
                                    <AnimatePresence initial={false}>
                                        {activeId === condition.id && (
                                            <motion.div
                                                key="content"
                                                initial={{ opacity: 0, height: 0 }}
                                                animate={{ opacity: 1, height: 'auto' }}
                                                exit={{ opacity: 0, height: 0 }}
                                                transition={{ duration: 0.3 }}
                                                className="answers"
                                            >
                                                <p>{condition.term}</p>
                                            </motion.div>
                                        )}
                                    </AnimatePresence>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            <Footer  toggleDarkMode={toggleDarkMode} toggleDarkTheme={toggleDarkTheme}  />
        </>
    );
}

export default TermsConditions;
