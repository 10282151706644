import React, { useContext } from "react";
import { motion } from "framer-motion";

import { LanguageContext } from './LanguageContext';
import translations from '../../Translations';
import './Header.css';
import Counter from './Counter';

import headerImage from '../../assets/header2.png';

import userImage1 from '../../assets/1.png';
import userImage2 from '../../assets/2.png';
import userImage3 from '../../assets/3.png';
import userImage4 from '../../assets/4.png';
import userImage5 from '../../assets/5.png';
import userImage6 from '../../assets/6.png';
import userImage7 from '../../assets/7.png';
import userImage8 from '../../assets/8.png';
import userImage9 from '../../assets/9.png';
import userImage10 from '../../assets/10.png';

import Typewriter from './Typewriter';

const Header = ({toggleDarkMode}) => {
  const { language } = useContext(LanguageContext);
  const t = translations[language];
  const langClass = language === 'ar' ? 'arabic' : 'english';

  const handleNavigation = (url) => {
    window.location.href = url;
  };

  

  return (
    <div className="header">
      <div className="container">

        
        <div className={`header-content ${langClass}`} dir={t.direction}>
          <div className="header-text-content">
            <h2>
              <span className="special-txt">{t.headerSubtitle}</span>
              <Typewriter text={t.headerTitle} delay={100} key={language} />
            </h2>
            <p className="header-txt">{t.headerParagraph}</p>
            <div className="header-buttons">
              <motion.button 
                whileTap={{ scale: 1.2 }} 
                className="btn btn2"
                // onClick={() => handleNavigation('https://play.google.com/store/apps/details?id=com.libyashop.ebuyer&hl=en&gl=US')}
                onClick={() => handleNavigation('https://onelink.to/z8v8t9')}
              >
                {t.joinBuyer}
              </motion.button>
              <motion.button 
                whileTap={{ scale: 1.1 }} 
                className="btn btn2"
                // onClick={() => handleNavigation('https://play.google.com/store/apps/details?id=com.libyashop.ebuyer&hl=en&gl=US')}
                onClick={() => handleNavigation('https://onelink.to/w29tgn')}
              >
                {t.joinSeller}
              </motion.button>
            </div>
          </div>
          <div className="header-image-content">
            <img src={headerImage} alt="Header" className="header-image" />
            <div className={`top-right-card ${toggleDarkMode ? 'bg-dark' : ''}`}>
                <span className="card-amount">
                    <Counter end={400} duration={2} />+
                </span>
                <p className="card-text">{t.cardText}</p>
                <div className="user-images">
                    <img src={userImage10} alt="" className="user-image" />
                    <img src={userImage9} alt="" className="user-image" />
                    <img src={userImage8} alt="" className="user-image" />
                    <img src={userImage7} alt="" className="user-image" />
                    <img src={userImage6} alt="" className="user-image" />
                    <img src={userImage5} alt="" className="user-image" />
                    <img src={userImage4} alt="" className="user-image" />
                    <img src={userImage3} alt="" className="user-image" />
                    <img src={userImage2} alt="" className="user-image" />
                    <img src={userImage1} alt="" className="user-image" />
                </div>
            </div>

            <div className={`bottom-left-card ${toggleDarkMode ? 'bg-dark' : ''}`}>
                <div className="card-section">
                    <div className="card-text">{t.cardText1}</div>
                    <div className="card-number">
                        <Counter end={7500} duration={3} separator={true} />+
                    </div>
                    <div className="card-border border1"></div>
                </div>
                <div className="card-section">
                    <div className="card-text">{t.cardText2}</div>
                    <div className="card-number">
                        <Counter end={100000} duration={3.5} separator={true} />+
                    </div>
                    <div className="card-border border2"></div>
                </div>
                <div className="card-section">
                    <div className="card-text">{t.cardText3}</div>
                    <div className="card-number">
                        <Counter end={140000} duration={3.5} separator={true} />+
                    </div>
                    <div className="card-border border3"></div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
