import React ,{ useContext}from 'react';
import { Player } from '@lottiefiles/react-lottie-player';
// import { FaRegCheckCircle } from 'react-icons/fa';
// import { Button } from '@mui/material';
import './PaymentSuccessPage.css';
// import Footer from "../../components/Main/Footer";


import { LanguageContext } from ".././Main/LanguageContext";
import translations from "../../Translations";


const PaymentSuccessPage = ({ toggleDarkMode, toggleDarkTheme }) => {
  const handleGoHome = () => {
    window.location.href = '/';
  };

  const { language } = useContext(LanguageContext);
  const t = translations[language];

  return (
<>

<div class={`parent-container  ${language === 'ar' ? 'arabic' : 'english'}`}>
    <div className="success-page-container">
      <Player
        autoplay
        loop
        // src="https://assets2.lottiefiles.com/packages/lf20_pqpmxbxp.json" 
        // src="https://lottie.host/8dcda6e2-57b6-4954-8299-53ef92df28d0/Tv1LxKmtFy.json" 
        src="https://lottie.host/939c3c08-999b-4e3d-b6b3-ebad52ff6e79/7QG6vAORZG.json" 
        // style={{ height: '150px', width: '150px' }}
      />

      <h1 className="title">
        {/* Payment Success!  */}
        {t.paymentSuccess }
        {/* <FaRegCheckCircle /> */}
      </h1>

      <p className="text">
        {/* Your payment was completed successfully. */}
        {t.successTxt}
      </p>

      <button className="home-button">
      {/* <button className="home-button" onClick={handleGoHome}> */}
        {/* Go Home */}
        {t.goHome}
      </button>
    </div>
</div>
{/* <Footer toggleDarkMode={toggleDarkMode} toggleDarkTheme={toggleDarkTheme} /> */}
</>
  );
};

export default PaymentSuccessPage;
