import React, { useContext, useState, useEffect } from "react";
import './RegisteredShops.css';
import img1 from '../assets/photo1.png';
import img2 from '../assets/photo2.png';
import img3 from '../assets/photo3.png';
import logo1 from '../assets/logo1.png';
import appStore from '../assets/app-btn.png';
import googlePlay from '../assets/google-btn.png';

import { LanguageContext } from "./Main/LanguageContext";
import translations from "../Translations";

const RegisteredShops = () => {
  const { language } = useContext(LanguageContext);
  const t = translations[language];
  const langClass = language === 'ar' ? 'arabic' : 'english';

  const [isResponsive, setIsResponsive] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsResponsive(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <>
      <div className={`shop_container ${langClass}`}>
        <div className='container'>
          <div className={`shop_cont ${isResponsive ? 'column' : ''}`}>
            {isResponsive ? (
              <>
                <div className="first">
                  <div className={`logo-cta ${langClass}`}>
                    <img src={logo1} alt="Logo" className="logo-img-shop" />
                  </div>
                  <h2 className='text-buyer'>{t.sectionOne2}</h2>
                </div>



                <p className={`text-shops ${langClass}`}>{t.paraSectionOne}</p>
                <div className="image-section">
                  <img src={img1} alt="" />
                </div>

                <div className={`cta ${langClass}`}>
                  <a href="https://apps.apple.com/ly/app/libyashop/id6477825495"><img src={appStore} alt="App Store" className="cta-button1" /></a>
                  <a href="https://play.google.com/store/apps/details?id=com.libyashop.ebuyer&hl=en&gl=US&fbclid=IwZXh0bgNhZW0CMTAAAR2SpE-A5UAatqNtD4DTbQ8CNj72Klku9_bX00-_LGDJFtQ9oXvFcP-Hgp8_aem_raPF6CXz_pXuQtC-tVX3sQ"><img src={googlePlay} alt="Google Play" className="cta-button2" /></a>
                </div>
              </>
            ) : (
              <>

                <div className="image-section">
                  <img src={img1} alt="" />
                </div>

                <div className={`text-section top-text ${langClass}`}>
                  <div className={`logo-cta ${langClass}`}>
                    <img src={logo1} alt="" className="logo-img-shop" />
                  </div>
                  <h2 className='text-buyer'>{t.sectionOne2}</h2>
                  <p className={`text-shops ${langClass}`}>{t.paraSectionOne}</p>
                  <div className={`cta ${langClass}`}>
                    <a href="https://apps.apple.com/ly/app/libyashop/id6477825495"><img src={appStore} alt="App Store" className="cta-button1" /></a>
                    <a href="https://play.google.com/store/apps/details?id=com.libyashop.ebuyer&hl=en&gl=US&fbclid=IwZXh0bgNhZW0CMTAAAR2SpE-A5UAatqNtD4DTbQ8CNj72Klku9_bX00-_LGDJFtQ9oXvFcP-Hgp8_aem_raPF6CXz_pXuQtC-tVX3sQ"><img src={googlePlay} alt="Google Play" className="cta-button2" /></a>
                  </div>
                </div>
              </>

            )}


          </div>
        </div>
      </div>




      <div className={`shop_container ${langClass}`}>
        <div className='container'>
          <div className={`shop_cont ${isResponsive ? 'column' : ''}`}>
            {isResponsive ? (
              <>
                <div className="first">
                  <div className={`logo-cta ${langClass}`}>
                    <img src={logo1} alt="Logo" className="logo-img-shop" />
                  </div>
                  <h2 className='text-buyer'>{t.shopTxt}</h2>
                </div>
                <p className={`text-shops ${langClass}`}>{t.paraShop}</p>
                <div className="image-section">
                  <img src={img2} alt="" />
                </div>

                <div className={`cta ${langClass}`}>
                  <a href="https://apps.apple.com/ly/app/libyashop/id6477825495"><img src={appStore} alt="App Store" className="cta-button1" /></a>
                  <a href="https://play.google.com/store/apps/details?id=com.libyashop.ebuyer&hl=en&gl=US&fbclid=IwZXh0bgNhZW0CMTAAAR2SpE-A5UAatqNtD4DTbQ8CNj72Klku9_bX00-_LGDJFtQ9oXvFcP-Hgp8_aem_raPF6CXz_pXuQtC-tVX3sQ"><img src={googlePlay} alt="Google Play" className="cta-button2" /></a>
                </div>
              </>
            ) : (

              <>
                <div className={`text-section top-text ${langClass}`}>
                  <div className={`logo-cta ${langClass}`}>
                    <img src={logo1} alt="" className="logo-img-shop" />
                  </div>
                  <h2 className='text-buyer'>{t.shopTxt}</h2>
                  <p className={`text-shops ${langClass}`}>{t.paraShop}</p>
                  <div className={`cta ${langClass}`}>
                    <a href="https://apps.apple.com/ly/app/libyashop-es/id6479678223"><img src={appStore} alt="App Store" className="cta-button1" /></a>
                    <a href="https://play.google.com/store/apps/details?id=com.libyashop.eshop&fbclid=IwZXh0bgNhZW0CMTAAAR3nFPtYv_nmoewBoZxpC8zgxBeyxWDJTzcfqP309XYR2YX-pOUvFoGXcSI_aem_YRYrmTvatTM6HGgctKyjNg"><img src={googlePlay} alt="Google Play" className="cta-button2" /></a>
                  </div>
                </div>

                <div className="image-section special-img">
                  <img src={img2} alt="" />
                </div>
              </>
            )}


          </div>
        </div>
      </div>


      <div className={`shop_container ${langClass}`}>
        <div className='container'>
          <div className={`shop_cont ${isResponsive ? 'column' : ''}`}>
            {isResponsive ? (
              <>
                <div className="first">
                  <div className={`logo-cta ${langClass}`}>
                    <img src={logo1} alt="Logo" className="logo-img-shop" />
                  </div>
                  <h2 className='text-buyer'>{t.buyerTxt}</h2>
                </div>


                <p className={`text-shops ${langClass}`}>{t.paraBuyer}</p>
                <div className="image-section">
                  <img src={img3} alt="" />
                </div>

                <div className={`cta ${langClass}`}>
                  <a href="https://apps.apple.com/ly/app/libyashop/id6477825495"><img src={appStore} alt="App Store" className="cta-button1" /></a>
                  <a href="https://play.google.com/store/apps/details?id=com.libyashop.ebuyer&hl=en&gl=US&fbclid=IwZXh0bgNhZW0CMTAAAR2SpE-A5UAatqNtD4DTbQ8CNj72Klku9_bX00-_LGDJFtQ9oXvFcP-Hgp8_aem_raPF6CXz_pXuQtC-tVX3sQ"><img src={googlePlay} alt="Google Play" className="cta-button2" /></a>
                </div>
              </>
            ) : (
              <>
                <div className="image-section">
                  <img src={img3} alt="" />
                </div>
                <div className={`text-section top-text ${langClass}`}>
                  <div className={`logo-cta ${langClass}`}>
                    <img src={logo1} alt="" className="logo-img-shop" />
                  </div>
                  <h2 className='text-buyer'>{t.buyerTxt}</h2>
                  <p className={`text-shops ${langClass}`}>{t.paraBuyer}</p>
                  <div className={`cta ${langClass}`}>
                    <a href="https://apps.apple.com/ly/app/libyashop/id6477825495"><img src={appStore} alt="App Store" className="cta-button1" /></a>
                    <a href="https://play.google.com/store/apps/details?id=com.libyashop.ebuyer&hl=en&gl=US&fbclid=IwZXh0bgNhZW0CMTAAAR2SpE-A5UAatqNtD4DTbQ8CNj72Klku9_bX00-_LGDJFtQ9oXvFcP-Hgp8_aem_raPF6CXz_pXuQtC-tVX3sQ"><img src={googlePlay} alt="Google Play" className="cta-button2" /></a>
                  </div>
                </div>
              </>
            )}

          </div>
        </div>
      </div>
    </>
  );
}

export default RegisteredShops;

