import React, { useEffect, useContext, useState } from "react";
import { useNavigate, NavLink } from "react-router-dom";
import { Navbar, Container, Nav, Offcanvas } from 'react-bootstrap';
import { GrLanguage } from "react-icons/gr";
import { MdWbSunny, MdDarkMode } from "react-icons/md"; 
import { Switch } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { LanguageContext } from './LanguageContext';
import translations from '../../Translations';
import logo from '../../assets/image 37 (1).png';
import darkLogo from '../../assets/footer-logo.png'; 
import './NavBar.css';
import ScrollToTopButton from './ScrollToTopButton';



function OffcanvasExample({ toggleDarkMode, toggleDarkTheme }) {
  const { language, toggleLanguage } = useContext(LanguageContext);
  const t = translations[language];
  const langClass = language === 'ar' ? 'arabic' : 'english';

  const [activeSection, setActiveSection] = useState('home');


  const navigate = useNavigate();
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };
  
  const handleNavigationAndScroll = (sectionId) => {
    if (window.location.pathname !== '/') {
      navigate('/', { state: { sectionId } });
    } else {
      scrollToSection(sectionId);
    }
    handleClose();
  };

  // useEffect(() => {
  //   const sectionId = window.history.state?.usr?.sectionId;
  //   if (sectionId) {
  //     scrollToSection(sectionId);
  //   }

  //   // Toggle body classes for dark/light mode
  //   document.body.classList.toggle('dark-mode', toggleDarkMode);
  //   document.body.classList.toggle('light-mode', !toggleDarkMode);
  // }, [toggleDarkMode]);

  // const darkTheme = createTheme({
  //   palette: {
  //     mode: toggleDarkMode ? 'dark' : 'light',
  //   },
  // });

  useEffect(() => {
    const sectionId = window.history.state?.usr?.sectionId;
    if (sectionId) {
      scrollToSection(sectionId);
    }

    // Track the active section based on scroll position
    const handleScroll = () => {
      const contactSection = document.getElementById('contact');
      const faqSection = document.getElementById('frequent');
      const scrollPosition = window.scrollY;

      if (faqSection && scrollPosition >= faqSection.offsetTop - 100) {
        setActiveSection('frequent');
      } else if (contactSection && scrollPosition >= contactSection.offsetTop - 100) {
        setActiveSection('contact');
      } else {
        setActiveSection('home');
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    document.body.classList.toggle('dark-mode', toggleDarkMode);
    document.body.classList.toggle('light-mode', !toggleDarkMode);
  }, [toggleDarkMode]);

  const darkTheme = createTheme({
    palette: {
      mode: toggleDarkMode ? 'dark' : 'light',
    },
  });

  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <Navbar bg={toggleDarkMode ? "dark" : ""} expand="lg" className={`mb-2 navBar ${toggleDarkMode ? 'navbar-dark' : 'bg-light-nav'}`} fixed="top" dir={t.direction}>
      {/* <Navbar bg={toggleDarkMode ? "dark" : "white"} expand="lg" className={`mb-2 navBar ${toggleDarkMode ? 'navbar-dark' : ''}`} fixed="top" dir={t.direction}> */}
        <Container className={`navbar-container container ${langClass}`}>
          <Navbar.Brand href="/">
            <img className="logoImg" src={toggleDarkMode ? darkLogo : darkLogo} alt="Logo" />
            {/* <img className="logoImg" src={toggleDarkMode ? darkLogo : logo} alt="Logo" /> */}
          </Navbar.Brand>
          <Navbar.Toggle 
            aria-controls="offcanvasNavbar" 
            onClick={handleShow} 
            className={toggleDarkMode ? 'burger-icon-dark' : 'burger-icon-dark'}
            // className={toggleDarkMode ? 'burger-icon-dark' : 'burger-icon-light'}
          />
          <Navbar.Offcanvas
            id="offcanvasNavbar"
            aria-labelledby="offcanvasNavbarLabel"
            placement="end"
            show={show}
            onHide={handleClose}
            // className={toggleDarkMode ? 'offcanvas-dark' : 'offcanvas-dark'}
            className={toggleDarkMode ? 'offcanvas-dark' : 'offcanvas-light'}
          >
            <Offcanvas.Header className={`offcanvas-header ${toggleDarkMode ? 'header-dark' : ''}`}>
              {/* <button type="button" className={`btn-close ${toggleDarkMode ? 'close-dark' : 'close-light'}`} aria-label="Close" onClick={handleClose}></button> */}

              <button
                type="button"
                className={`btn-close ${toggleDarkMode ? 'close-darkBtn' : ''}`}
                // className={`btn-close ${toggleDarkMode ? 'text-white' : 'text-black'}`}
                aria-label="Close"
                onClick={handleClose}
              ></button>
              {/* moblie */}
              <Offcanvas.Title id="offcanvasNavbarLabel">
                <img className="logoImg" src={toggleDarkMode ? darkLogo : logo} alt="Logo2" />
                {/* <img className="logoImg" src={toggleDarkMode ? darkLogo : logo} alt="Logo" /> */}
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Nav className="justify-content-center flex-grow-1 pe-3 mt-3">
                <NavLink  to="/" className={`nav-link ${langClass}`} onClick={() => handleNavigationAndScroll('/')} end>{t.homeLink}</NavLink>
                <NavLink to="/" onClick={() => handleNavigationAndScroll('contact')}  className={`nav-link ${langClass}`}>{t.contactLink}</NavLink>
                <NavLink to="/" onClick={() => handleNavigationAndScroll('frequent')}  className={`nav-link ${langClass}`}>{t.fqLink}</NavLink>
                <NavLink to="/terms" className={`nav-link ${langClass}`} onClick={() => handleNavigationAndScroll('terms')}>{t.termsLink}</NavLink>
                <NavLink to="/event/workshop" className={`nav-link ${langClass}`}>{t.eventWorkshop}</NavLink>
                {/* <NavLink to="/terms" activeClassName="active-link" className={`nav-link ${langClass}`} onClick={() => handleNavigationAndScroll('terms')}>{t.termsLink}</NavLink> */}
              </Nav>

              <span onClick={toggleLanguage} className="language-toggle-btn">
                <GrLanguage />
                {t.languageToggle}
              </span>

              <div className="switcher-btn" style={{ display: 'flex', alignItems: 'center', marginTop: '1rem' }}>
                <MdDarkMode className={`dark-icon ${toggleDarkMode ? 'dark-icon-dark' : 'dark-icon-dark1'}`} style={{ marginLeft: '8px' }} />
                {/* <MdDarkMode className={`dark-icon ${toggleDarkMode ? 'dark-icon-dark' : 'dark-icon-dark1'}`} style={{ marginLeft: '8px' }} /> */}

                <Switch
                  checked={toggleDarkMode}
                  onChange={toggleDarkTheme}
                />
                <MdWbSunny className={`light-icon ${toggleDarkMode ? 'light-icon-dark' : 'light-icon-dark1'}`} style={{ marginRight: '8px' }} />

              </div>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>
      <div style={{ marginTop: '56px' }}></div>
      <ScrollToTopButton isSidebarOpen={show} />
    </ThemeProvider>
  );
}

export default OffcanvasExample;