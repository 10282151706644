import React, { useState, useEffect } from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './components/Pages/Home.js';
import TermsConditions from './components/Pages/Terms.js';
import Checkout from './components/Pages/Checkout.js';
import PaymentSuccessPage from './components/Pages/PaymentPage.js';
import EventWorkshop from "./components/Pages/EventWorkshop.js";
// import OffcanvasExample from './components/Main/NavBar.js';

export default function App() {
  // Initialize state with a default value / from localStorage
  const [toggleDarkMode, setToggleDarkMode] = useState(() => {
    const savedMode = localStorage.getItem('darkMode');
    return savedMode === 'true';
  });

  useEffect(() => {
    // Save the theme mode to localStorage whenever it changes
    localStorage.setItem('darkMode', toggleDarkMode);
  }, [toggleDarkMode]);

  const toggleDarkTheme = () => {
    setToggleDarkMode(prevMode => !prevMode);
  };

  const darkTheme = createTheme({
    palette: {
      mode: toggleDarkMode ? 'dark' : 'light',
      primary: {
        main: '#90caf9',
      },
      secondary: {
        main: '#131052',
      },
    },
  });

  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <Router>
        <Routes>
          <Route path="/" element={<Home toggleDarkMode={toggleDarkMode} toggleDarkTheme={toggleDarkTheme} />} />
          <Route path="/terms" element={<TermsConditions  toggleDarkMode={toggleDarkMode} toggleDarkTheme={toggleDarkTheme} />} />
          <Route path="/event/workshop" element={<EventWorkshop  toggleDarkMode={toggleDarkMode} toggleDarkTheme={toggleDarkTheme} />} />
          <Route 
          path="/checkout"
          element={<Checkout  toggleDarkMode={toggleDarkMode} toggleDarkTheme={toggleDarkTheme} />} />
          <Route 
          path="/payment_tlync_ls_wallet"
          // path="/payment" 
          element={<PaymentSuccessPage  toggleDarkMode={toggleDarkMode} toggleDarkTheme={toggleDarkTheme} />} />
        </Routes>
      </Router>
    </ThemeProvider>
  );
}
