import React, { useState, useContext } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";

import { LanguageContext } from './Main/LanguageContext';
// import translations from '../Translations';

const Accordion = ({ questions, answer, isLast }) => {
    const [text, setText] = useState(false);
    const { language } = useContext(LanguageContext);
    // const t = translations[language].faq || translations[language];
    // const direction = language === 'ar' ? 'rtl' : 'ltr';
    const langClass = language === 'ar' ? 'arabic' : 'english';


    const display = () => {
        setText(!text);
    };

    return (
        <div className="container">
            <div className={`questions-container ${isLast ? 'last-question' : ''} ${text ? 'open' : ''}  ${langClass}`}>
                <div className="question" onClick={display}>
                    <h2>{questions}</h2>
                    <p>{text ? <IoIosArrowUp /> : <IoIosArrowDown />}</p>
                </div>
                <AnimatePresence initial={false}>
                    {text && (
                        <motion.div
                            key="content"
                            initial={{ opacity: 0, height: 0 }}
                            animate={{ opacity: 1, height: 'auto' }}
                            exit={{ opacity: 0, height: 0 }}
                            transition={{ duration: 0.3 }}
                            className="answers"
                        >
                            <p>{answer}</p>
                        </motion.div>
                    )}
                </AnimatePresence>
            </div>
        </div>
    );
};

export default Accordion;

