import React, { useEffect, useState } from 'react';

const Counter = ({ end, duration, separator }) => {
    const [count, setCount] = useState(0);

    useEffect(() => {
        const incrementValue = Math.ceil(end / (duration * 60));
        let start = 0;

        const interval = setInterval(() => {
            start += incrementValue;
            if (start >= end) {
                setCount(end);
                clearInterval(interval);
            } else {
                setCount(start);
            }
        }, 1000 / 60); 

        return () => clearInterval(interval);
    }, [end, duration]);

    const formatNumber = (num) => {
        return separator ? num.toLocaleString() : num;
    };

    return <span>{formatNumber(count)}</span>;
};

export default Counter;
