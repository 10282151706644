import React, { useContext } from 'react';

import Slider from "react-slick";
// import { FaQuoteRight } from "react-icons/fa";

import userImage1 from '../assets/avatar.png';
import userImage2 from '../assets/e-store.png';
import userImage3 from '../assets/ethraa.png';
import vector from '../assets/Vector.svg';
import { LanguageContext } from './Main/LanguageContext';
import translations from '../Translations';
import './ReviewUserCard.css';

// motion
import { motion } from 'framer-motion';
// variants
import { fadeIn } from '../variants';

const cardsData = [
  {
    id: 1,
    text: "انتوا افضل تطبيق مشتريات في ليبيا ♥",
    image: userImage1,
    name: "معتز منصور",
  },
  {

    id: 2,
    text: "جيد جدا، ساعد في تبسيط إدارة متجري",
    image: userImage2,
    name: "Everything Online Store",
  },
  {
    id: 3,
    text: `
    يوفر حلًا ممتازًا لمتجري
    `,
    image: userImage3,
    name: "عباية إثراء ",
  },
  {
    id: 4,
    text: "جيد جدا، ساعد في تبسيط إدارة متجري",
    image: userImage2,
    name: "Everything Online Store",
  },
];

const ReviewUserCard = ({toggleDarkMode}) => {
  const { language } = useContext(LanguageContext);
  const t = translations[language];
  const langClass = language === 'ar' ? 'arabic' : 'english';

  
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 2,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };

  return (
    <div className='container'>
      <motion.div 
       variants={fadeIn('up', 0.3)}
       initial="hidden"
       whileInView={"show"}
       viewport={{ once: false, amount: 0.7}}
       
      
      className={`review-container ${langClass}`}>
        <div className="cards-container">
          <div className='title-customers'>
            <h1 className='customers'>{t.reviewTitle}</h1>
            <p className='review-title-txt'>{t.reviewTitleSpecial}</p>
          </div>
        </div>

        <div className='all-customers' dir='rtl'>
          <Slider {...settings}>
            {cardsData.map(card => (
              <div key={card.id} className={`card-users  ${toggleDarkMode ? 'bg-dark' : ''}`}>
                <div className='quote-container'>
                  {/* <FaQuoteRight className='quotes special-txt' /> */}
                  <img src={vector}></img>
                </div>
                <p className={`comment-reviewer ${langClass}`}>{card.text}</p>
                <span className='line'></span>
                <div className="user-info">
                  <div className="user-data">
                    <span className={`users-name ${langClass}`}>{card.name}</span>
                  </div>
                  <img src={card.image} alt={card.name} className="user-image" />
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </motion.div>
    </div>
  );
};

export default ReviewUserCard;
