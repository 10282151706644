

import { initializeApp } from "firebase/app";
import { getFirestore } from "@firebase/firestore";

const firebaseConfig = {
  
  apiKey: "AIzaSyDsMR9BjCoctyGVe8TcbfT0UWPmbW3gRAE",
  authDomain: "libya-d369a.firebaseapp.com",
  databaseURL: "https://libya-d369a-default-rtdb.firebaseio.com",
  projectId: "libya-d369a",
  storageBucket: "libya-d369a.appspot.com",
  messagingSenderId: "999788899058",
  appId: "1:999788899058:web:5cdd8a888aca4d478cfe6b",


  // apiKey: process.env.REACT_FIREBASE_APP_API_KEY,
  // authDomain: process.env.REACT_FIREBASE_APP_AUTH_DOMAIN ,
  // databaseURL: process.env.REACT_FIREBASE_APP_DATABASE_URL,
  // projectId: process.env.REACT_FIREBASE_APP_PROJECT_ID,
  // storageBucket: process.env.REACT_FIREBASE_APP_STORAGE_BUCKET,
  // messagingSenderId: process.env.MESSAGING_SENDER_ID,
  // appId: process.env.REACT_APP_APP_ID


};

// Initialize Firebase

const app = initializeApp(firebaseConfig);
const firestore = getFirestore(app);

const db = getFirestore(app);


export { firestore, db };
