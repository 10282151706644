import React, { useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import './Footer.css';
import ScrollToTopButton from "./ScrollToTopButton";
import { LanguageContext } from '../Main/LanguageContext';
import translations from '../../Translations';

import footerLogo from "../../assets/footer-logo.png";
import facebookIcon from "../../assets/facebook.svg";
import instaIcon from "../../assets/instagram.svg";
import linkedinIcon from "../../assets/linkedIn.svg";
import tiktokIcon from "../../assets/tiktok.svg";


import { ThemeProvider, createTheme } from "@mui/material/styles";

const Footer = ({ toggleDarkMode }) => {
	const { language } = useContext(LanguageContext);
	const t = translations[language];
	const navigate = useNavigate();

	const scrollToSection = (sectionId) => {
		const section = document.getElementById(sectionId);
		if (section) {
			section.scrollIntoView({ behavior: 'smooth' });
		}
	};

	const handleNavigationAndScroll = (sectionId) => {
		if (window.location.pathname !== '/') {
			navigate('/', { state: { sectionId } });
		} else {
			scrollToSection(sectionId);
		}
	};

	useEffect(() => {
		const sectionId = window.history.state?.usr?.sectionId;
		if (sectionId) {
			scrollToSection(sectionId);
		}


		// Toggle body classes for dark/light mode
		document.body.classList.toggle('dark-mode', toggleDarkMode);
		document.body.classList.toggle('light-mode', !toggleDarkMode);
	}, [toggleDarkMode]);

	const darkTheme = createTheme({
		palette: {
			mode: toggleDarkMode ? 'dark' : 'light',
		},
	});

	return (
		<ThemeProvider theme={darkTheme}>
			<footer className={`footer ${language === 'ar' ? 'arabic' : 'english'} ${toggleDarkMode ? 'background-dark' : 'background-light'}`} dir={t.direction}>
				<div className="container">
					<div className="footer-row">
						<div className="footer-col">
							<img className="img-responsive" src={footerLogo} alt="logo" />
							<div className="footer-phone">
								<span className="footer-txt">{t.footerLicence}</span>
								<span className="footer-txt">{t.supportTxt}</span>
							</div>
							<div className="footer-phone">
								<span className="footer-txt">{t.phoneNum}</span>
								<span className="footer-txt">{t.otherTxt}</span>
							</div>
						</div>

						<div className="footer-col">
							<h4>{t.menus}</h4>
							<ul>
								<li><a href="/">{t.main}</a></li>
								<li className='footer-link' onClick={() => handleNavigationAndScroll('contact')}>{t.contact}</li>
								<li><a href="/event/workshop">{t.eventWorkshop}</a></li>

							</ul>
						</div>

						<div className="footer-col">
							<h4>{t.otherLinks}</h4>
							<ul>
								<li onClick={() => handleNavigationAndScroll('frequent')} className='footer-link'>{t.faqTitle}</li>
								<li><a href="/terms">{t.privacyPolicy}</a></li>
							</ul>
						</div>

						<div className="footer-col">
							<h4>{t.socialMedia}</h4>
							<div className="social-links">
								<a href="https://www.facebook.com/LibyaShop2023/?paipv=0&eav=AfZR6tg81WAbmMBiNr43QG6V4k9VvHx9yWL4hag7A_1bBbVQCH0IdG-WbMAKuPD90fs">
									<img src={facebookIcon} alt="" />
								</a>
								<a href="https://www.linkedin.com/company/libyashop/">
									<img src={linkedinIcon} alt="" />
								</a>
								<a href="https://www.tiktok.com/@libyashop.ly">
									<img src={tiktokIcon} alt="" />
								</a>
								<a href="https://www.instagram.com/libyashop.ly/?hl=en">
									<img src={instaIcon} alt="" />
								</a>
							</div>
						</div>
					</div>
				</div>
				<hr style={{ marginBottom: "30px", color: "#C7C7C7" }} />
				<p className="styled-paragraph" style={{ marginBottom: "0", paddingBottom: "20px" }}>{t.copyRight}</p>
				<ScrollToTopButton />
			</footer>
		</ThemeProvider>
	);
};

export default Footer;
