import React, { useContext } from 'react';
import { LanguageContext } from './Main/LanguageContext';
import translations from '../Translations';
import "./Payment.css";

import sadad from "../assets/sadad2.png";
import moamalat from "../assets/moamalat.png";
import cash from "../assets/cash.png";
import tadawl from "../assets/tadawl.svg";
import moby from "../assets/moby.png";
import edfa3ly from "../assets/edf3ly.png";


function Payment({ toggleDarkMode }) {
  const { language } = useContext(LanguageContext);
  const t = translations[language];
  const langClass = language === 'ar' ? 'arabic' : 'english';

  return (
    <div className={`payment-container ${langClass} ${toggleDarkMode ? 'background-dark' : 'background-light'}`}>
      <div className='container'>
        <div className={`payment-flex ${langClass}`}>
          <div className='left-div'>
            <div className='image-row bottom'>
                {/* className={`${toggleDarkMode ? 'bg-dark' : ''}`} */}
              <img src={sadad} alt='' />
              <img src={moamalat} alt='' />
              <img src={cash} alt='' />
            </div>
            <div className='image-row'>
              <img src={tadawl} alt='' />
              <img src={edfa3ly} alt='' />
              <img src={moby} alt='' />
            </div>
          </div>
          <div className={`right-div ${langClass}`}>
            <h2 className={`payment-title ${langClass}`}>{t.paymentTitle}</h2>
            <p className={`payment-txt ${langClass}`}>{t.paymentTxt}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Payment;
